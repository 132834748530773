import React, { useEffect, useState } from "react"
import axios from "axios"
import { useParams } from "react-router-dom"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Details = () => {
  const { id } = useParams()
  const [oneCake, setOneCake] = useState()
  const [conditioning, setConditioning] = useState([])
  const [price, setPrice] = useState("")
  const [selectedSize, setSelectedSize] = useState("")

  const getSizeFromLibelle = (libelle) => {
    if (libelle.includes("personne")) {
      return libelle.split("per")[0]
    } else if (libelle.includes("choux")) {
      return libelle.split("cho")[0]
    } else {
      return libelle.split("coo")[0]
    }
  }

  const handleDevisClick = () => {
    // Récupérer l'ID du gâteau et le libellé Format
    const cakeId = id
    const selectedFormat = selectedSize
    // Stocker ces valeurs dans le Local Storage
    localStorage.setItem("selectedCakeId", cakeId)
    localStorage.setItem("selectedFormat", selectedFormat)
    localStorage.setItem("selectedPrice", price)

    // Rediriger l'utilisateur vers la page Formulaire
    const size = getSizeFromLibelle(selectedFormat)
    window.location.href = `/devis?cakeId=${cakeId}&size=${size}`
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/details/${id}`).then((res) => {
      setOneCake(res.data)
      console.log(`affichage du détails d'un gateau : `)
      setConditioning(res.data.conditioning)
      setPrice(res.data.conditioning[0].price)
      setSelectedSize(res.data.conditioning[0].libelle)
    })
  }, [id])
  const handleSizeChange = (e) => {
    const selectedOption = conditioning.find((OneConditioning) => OneConditioning.libelle === e.target.value)
    if (selectedOption) {
      setPrice(selectedOption.price)
      setSelectedSize(selectedOption.libelle)
    }
  }

  return (
    <div className="wrapper">
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois{" "}
      </div>
      <section className="container-details">
        {oneCake && (
          <section className="card">
            <article className="dp-fx">
              <Carousel>
                {oneCake.img.map((image, index) => (
                  <div className="carousel-slide-img" key={index}>
                    <img
                      className="test"
                      src={`${process.env.REACT_APP_API}/img/${image}`}
                      loading="lazy"
                      alt={`${oneCake.name} - ${index + 1}`}
                      aria-label={`Image ${index + 1} du gâteau`}
                    />
                  </div>
                ))}
              </Carousel>
              <article className="details-patisserie">
                <h2 className="title-name">{oneCake.name}</h2>
                <p className="category">{oneCake.category}</p>
                <p className="description">{oneCake.description}</p>
                <p className="description">{oneCake.composition}</p>
                <p className="description">{oneCake.alergenes}</p>
                <div className="bg-color">
                  <select className="select" name="quantity" onChange={handleSizeChange} value={selectedSize}>
                    {conditioning.map((OneConditioning, i) => (
                      <option key={i} value={OneConditioning.libelle}>
                        {OneConditioning.libelle}
                      </option>
                    ))}
                  </select>
                  <p className="price">{price} €</p>
                  <button className="btn btn-devis" onClick={handleDevisClick}>
                    Faire un devis
                  </button>
                </div>
              </article>
            </article>
          </section>
        )}
      </section>
    </div>
  )
}

export default Details
