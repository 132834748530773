import React, { useState, useEffect } from "react"
import axios from "axios"
import { Carousel } from "react-responsive-carousel"
import { Link } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Accueil = () => {
  const [topCakes, setTopCakes] = useState([])

  useEffect(() => {
    const fetchTopCakes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/topcake`)
        setTopCakes(response.data)
      } catch (error) {
        console.error("Error fetching top cakes:", error)
      }
    }
    fetchTopCakes()
  }, [])

  return (
    <>
      <div className="displaynone">
        Jay Pastry, Jason Seban, Crazy Framboisier, Number Cake, Crazy Fraisier, Pav'Love, Fruits rouges, Mangue
        Passion, Tart'O Frayz, Flan Vanille Bleu, Tart'O Framboisy, Cooky's, Galette des rois
      </div>
      <main className="container-home">
        <section className="home">
          <h2>Gâteau du moment</h2>
          <div className="custom-carousel-wrapper">
            <Carousel>
              {topCakes.map((cake) => (
                <Link to={`/details/${cake._id}`} key={cake._id}>
                  <div className="container-slide-img" key={cake._id}>
                    <img
                      className="test"
                      src={`${process.env.REACT_APP_API}/img/${cake.img[0]}`}
                      loading="lazy"
                      alt={cake.name}
                      aria-label={`Image du gâteau ${cake.name}`}
                    />
                  </div>
                </Link>
              ))}
            </Carousel>
          </div>
          <Link to="/mes-patisseries">
            <button className="btn btn-pastry">Découvrir les pâtisseries</button>
          </Link>
        </section>
        <section>
          <h2>À propos de moi</h2>
          <article className="description">
            Bienvenue dans mon univers gourmand ! Je suis Jason Seban, Chef Pâtissier passionné. Explorez mes créations
            uniques, des moments magiques passés à créer des gâteaux qui racontent chacun une histoire de saveurs et de
            souvenirs...
          </article>
          <Link to="/a-propos-de-moi#top">
            <button className="btn">À propos de moi</button>
          </Link>
        </section>
        <section>
          <h2>Vidéo du moment</h2>
          <article className="article-video">
            <video
              controls
              width="326"
              height="500"
              aria-label="vidéo de la réalisation d'une Pavlova aux fruits rouges"
            >
              <source
                src={`${process.env.REACT_APP_API}/img/Pavlove-Fruit-rouge.mp4`}
                type="video/mp4"
                alt="Réalisation Pav'love fruits rouges"
              />
              Votre navigateur ne prend pas en charge la lecture de vidéos.
            </video>
          </article>
        </section>
      </main>
    </>
  )
}

export default Accueil
